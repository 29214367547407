import Card from "components/Card";
import projetos from "json/db.json";
import styles from "./Projeto.module.css";

const Projetos = () => {
  return (
    <section className={styles.section}>
      <h2 className={styles.titulo}>Projetos</h2>
      <p className={styles.descricao}>Coisas que fiz até agora</p>
      <div className={styles.projetos}>
        {projetos.map((projeto) => (
          <Card {...projeto} key={projeto.id} />
        ))}
      </div>
    </section>
  );
};

export default Projetos;
