import styles from "./Rodape.module.css";
import RodapeLink from "components/RodapeLink";

const Rodape = () => {
  return (
    <footer className={styles.rodape}>
      <nav className={styles.navegacao}>
        <RodapeLink url="/">Início</RodapeLink>
        <RodapeLink url="/sobre">Sobre Mim</RodapeLink>
        <RodapeLink url="/stacks">Tech Stack</RodapeLink>
        <RodapeLink url="/projetos">Projetos</RodapeLink>
        <RodapeLink url="/contato">Contato</RodapeLink>
      </nav>
      <div>
        Feito por <span className={styles.gradiente}>Matheus Menezes</span> com{" "}
        <span className={styles.gradiente}>ReactJS</span> e{" "}
        <span className={styles.gradiente}>NodeJS</span>
      </div>
    </footer>
  );
};

export default Rodape;
