import styles from "./Projetos.module.css";
import Cabecalho from "components/Cabecalho";
import Container from "components/Container";
import Contatos from "components/Contatos";
import Projetos from "components/Projetos";
import Rodape from "components/Rodape";

const ProjetosRealizados = () => {
  return (
    <Container>
      <Cabecalho />
      <div className={styles.projetos}>
        <Projetos />
      </div>
      <Contatos />
      <Rodape />
    </Container>
  );
};

export default ProjetosRealizados;
