import styles from "./Tecnologias.module.css";
import stacks from "json/stacks.json";

const Tecnologias = () => {
  return (
    <section className={styles.container} id="stacks">
      <h2 className={styles.titulo}>Minha Stack de Tecnologias</h2>
      <p className={styles.texto}>Tecnologias que trabalho com frequência</p>
      <div className={styles.tecnologias}>
        {stacks.map((stack) => (
          <img
            src={stack.imagem}
            alt={stack.nome}
            className={styles.imagem}
            title={stack.nome}
            key={stack.id}
          />
        ))}
      </div>
    </section>
  );
};

export default Tecnologias;
