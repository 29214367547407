import styles from "./SobreMim.module.css";
import Cabecalho from "components/Cabecalho";
import Container from "components/Container";
import Rodape from "components/Rodape";
import Contatos from "components/Contatos";
import empresa from "assets/empresa.svg";
import calendario from "assets/calendario.png";
import local from "assets/local.png";
import experiencias from "json/experiencias.json";
import formacoes from "json/formacoes.json";

const SobreMim = () => {
  return (
    <Container>
      <Cabecalho />
      <div className={styles.sobre}>
        <h2 className={styles.titulo}>Sobre Mim</h2>
        <p className={styles.descricao}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis quaerat
          earum impedit ad fugit ipsa deleniti debitis voluptas recusandae
          perferendis. Alias delectus consectetur labore, soluta debitis
          doloremque odio ipsum optio quis neque et numquam earum architecto,
          tempora quae, exercitationem quo omnis similique assumenda repellat
          reprehenderit. Delectus maxime a quae velit.
        </p>
        <h2 className={styles.titulo}>Experiência</h2>
        <ul className={styles.lista}>
          {experiencias.map((experiencia) => (
            <li className={styles.item} key={experiencia.id}>
              <span className={styles.cargo}>{experiencia.cargo}</span>
              <div className={styles.detalhes}>
                <div className={styles.local}>
                  <div className={styles.posicao}>
                    <img src={empresa} alt="" />
                    <span>{experiencia.empresa}</span>
                  </div>
                  <div className={styles.posicao}>
                    <img src={local} alt="" />
                    <span>{experiencia.cidade}</span>
                  </div>
                </div>
                <div className={styles.posicao}>
                  <img src={calendario} alt="" />
                  <span>{experiencia.periodo}</span>
                </div>
              </div>
              <hr className={styles.divisao} />
            </li>
          ))}
        </ul>
        <h2 className={styles.titulo}>Formação</h2>
        <ul className={styles.lista}>
          {formacoes.map((formacao) => (
            <li className={styles.item} key={formacao.id}>
              <span className={styles.cargo}>{formacao.titulo}</span>
              <div className={styles.detalhes}>
                <div className={styles.posicao}>
                  <img src={empresa} alt="" />
                  <span>{formacao.instituicao}</span>
                </div>
                <div className={styles.posicao}>
                  <img src={calendario} alt="" />
                  <span>{formacao.periodo}</span>
                </div>
              </div>
              <hr className={styles.divisao} />
            </li>
          ))}
        </ul>
      </div>
      <Contatos />
      <Rodape />
    </Container>
  );
};

export default SobreMim;
