import Cabecalho from "components/Cabecalho";
import Container from "components/Container";
import Contatos from "components/Contatos";
import Rodape from "components/Rodape";
import styles from "./Contato.module.css";

const Contato = () => {
  return (
    <Container>
      <Cabecalho />
      <div className={styles.contato}>
        <div className={styles.email}>
          <h2 className={styles.titulo}>
            Para qualquer questão, por favor envie um email para
          </h2>
          <span className={styles.descricao}>medins.jobs@gmail.com</span>
        </div>
        <div className={styles.numero}>
          <h2 className={styles.titulo}>ou envie uma mensagem para</h2>
          <span className={styles.descricao}>+55(92) 99324-9455</span>
        </div>
      </div>
      <Contatos />
      <Rodape />;
    </Container>
  );
};

export default Contato;
