import { Link } from "react-router-dom";
import styles from "./Cabecalho.module.css";
import git from "assets/github.svg";
import linkedin from "assets/linkedin.svg";
import CabecalhoLink from "../CabecalhoLink";

const Cabecalho = () => {
  return (
    <header className={styles.cabecalho}>
      <Link to="/" className={styles.link}>
        <p className={styles.logo}>menezes-dev</p>
      </Link>
      <nav className={styles.navegacao}>
        <CabecalhoLink url="/">Início</CabecalhoLink>
        <CabecalhoLink url="/sobre#">Sobre Mim</CabecalhoLink>
        <CabecalhoLink url="/stacks">Tech Stack</CabecalhoLink>
        <CabecalhoLink url="/projetos">Projetos</CabecalhoLink>
        <CabecalhoLink url="/contato">Contato</CabecalhoLink>
      </nav>
      <div className={styles.midias}>
        <a href="https://github.com/menezes-dev" className={styles.link}>
          <img src={git} alt="Logo do Github" />
        </a>
        <a
          href="https://www.linkedin.com/in/menezes-dev/"
          className={styles.link}
        >
          <img src={linkedin} alt="Logo do Linkedin" />
        </a>
      </div>
    </header>
  );
};

export default Cabecalho;
