import styles from "./Banner.module.css";
import perfil from "assets/perfil.jpg";

const Banner = () => {
  return (
    <>
      <section className={styles.banner}>
        <p className={styles.texto}>
          Olá 👋, Meu nome é{" "}
          <span className={styles.nome}>Matheus Menezes</span> e eu faço
          soluções para Web
        </p>
        <img src={perfil} alt="Foto de perfil" className={styles.imagem} />
      </section>
    </>
  );
};

export default Banner;
