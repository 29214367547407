import styles from "./Contatos.module.css";
import github from "assets/github.svg";
import linkedin from "assets/linkedin.svg";
import { Link } from "react-router-dom";

const Contatos = () => {
  return (
    <>
      <section className={styles.contatos}>
        <Link to="/" className={styles.link}>
          <p className={styles.logo}>menezes-dev</p>
        </Link>
        <div className={styles.info}>
          <div className={styles.telefone}>
            <a
              href="https://api.whatsapp.com/send?phone=5592993249455"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.telefone_link}
            >
              +55 (92) 99324-9455
            </a>
          </div>
          <div className={styles.email}>
            <a
              href="mailto:medins.jobs@gmail.com?subject=Portifólio da Web&body=Vi seu portifólio e gostaria de conversar"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.email_link}
            >
              medins.jobs@gmail.com
            </a>
          </div>
          <div className={styles.midias}>
            <a href="https://github.com/menezes-dev">
              <img src={github} alt="Link para o meu Github" />
            </a>
            <a href="https://www.linkedin.com/in/menezes-dev/">
              <img src={linkedin} alt="Link para o meu LinkedIn" />
            </a>
          </div>
        </div>
      </section>
      <hr />
    </>
  );
};

export default Contatos;
