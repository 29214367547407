import Banner from "components/Banner";
import Cabecalho from "components/Cabecalho";
import Tecnologias from "components/Tecnologias";
import Projetos from "components/Projetos";
import Contatos from "components/Contatos";
import Rodape from "components/Rodape";
import Container from "components/Container";
// import styles from "./PaginaInicial.module.css";

const PaginaInicial = () => {
  return (
    <>
      <Container>
        <Cabecalho />
        <Banner />
        <Tecnologias />
        <Projetos />
        <Contatos />
        <Rodape />
      </Container>
    </>
  );
};

export default PaginaInicial;
