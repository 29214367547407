import styles from "./Card.module.css";
import deploy from "assets/deploy.svg";
import code from "assets/code.svg";

const Card = ({ titulo, descricao, capa, tags }) => {
  console.log("Caminho", capa);

  return (
    <div className={styles.card}>
      <div className={styles.capa}>
        <img src={capa} alt="" className={styles.imagem} />
      </div>
      <div className={styles.conteudo}>
        <h2 className={styles.titulo}>{titulo}</h2>
        <p className={styles.descricao}>{descricao}</p>

        <p className={styles.tags}>
          <span>Tech Stack:</span> {tags}
        </p>
        <div className={styles.preview}>
          <a href="www.google.com.br" className={styles.deploy}>
            <img src={deploy} alt="" />
            <span>Live Preview</span>
          </a>
          <a href="www.google.com.br" className={styles.code}>
            <img src={code} alt="" />
            <span>View Code</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Card;
