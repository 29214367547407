import Contato from "pages/Contato";
import PaginaInicial from "pages/PaginaInicial";
import ProjetosRealizados from "pages/ProjetosRealizados";
import SobreMim from "pages/SobreMim";
import TechStack from "pages/TechStack";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PaginaInicial />} />
        <Route path="/sobre" element={<SobreMim />} />
        <Route path="/stacks" element={<TechStack />} />
        <Route path="/projetos" element={<ProjetosRealizados />} />
        <Route path="/contato" element={<Contato />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
